import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Divider from "@material-ui/core/Divider";

import Link from '@material-ui/core/Link';

import { LockOutlined, LockOpenOutlined } from '@material-ui/icons';
import { api, handleError } from "../helpers/api";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = theme => ({
	cardGrid: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	cardMedia: {
		paddingTop: '100%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
	},
	footer: {
		padding: theme.spacing(6),
	},
	type: {
		position: 'absolute',
		width: 'min-content',
		top: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 1000
	},
	noImage: {
		paddingRight: "88.750px"
	},
	minGrid: {
		height: "min-content"
	}
});

class IndexPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			services: null
		};
	}

	async componentDidMount() {
		try {
			const response = await api.get('/services');
			let services = await response.data;
			services.sort(function (a, b) {
				let aTitle = a["title"]
					.toLowerCase()
					.replace('ä', 'a')
					.replace('ö', 'o')
					.replace('ü', 'u')
					.replace(/[^A-Za-z0-9]/g, '');
				let bTitle = b["title"]
					.toLowerCase()
					.replace('ä', 'a')
					.replace('ö', 'o')
					.replace('ü', 'u')
					.replace(/[^A-Za-z0-9]/g, '');
				if (aTitle < bTitle) {
					return -1;
				}
				if (bTitle < aTitle) {
					return 1;
				}
				return 0;
			});

			// Get the returned users and update the state.
			this.setState({ services: services });
		} catch (error) {
			alert(`Something went wrong while fetching data: \n${handleError(error)}`);
		}
	}

	render(){
		const { classes } = this.props;

		return (
			<Box>
				<Container maxWidth="sm">
					<Box m={6}>
						<Typography variant="h2" component="h1" gutterBottom align="center">
							Oli's Tools
						</Typography>
					</Box>
				</Container>
				<Container>
					<Grid container spacing={4} justify="center" className="grid-outside">
						{this.state.services?
							this.state.services.map((service) => (
								<Grid item key={service["url"]} xs={12} sm={6} md={4} lg={3} className={"grid-inside "+classes.minGrid}>
									<Card
										className={classes.card}
										onClick={service["type"] === 'Podcast'?() => {
											this.props.history.push(service['url']);
										}:``}
									>
										{"image" in service ?
											<>
												<CardMedia
													className={classes.cardMedia}
													image={service["image"]}
												/>
												<Chip label={service["type"]} color="secondary" className={classes.type}/>
											</>
											:
											<Chip label={service["type"]} color="secondary" className={classes.type}/>
										}
										<CardContent className={classes.cardContent}>
											<Typography
												gutterBottom variant="h5"
												component="h2"
												className={"image" in service?``:classes.noImage}
											>
												{service["title"]}
											</Typography>
											{"description" in service ?
												<Typography variant="caption">
													{service["description"]}
												</Typography>:``
											}
										</CardContent>
										<CardActions>
											<Box flexGrow={1}>
												<Link href={"https://api.oli.fyi" + service['url']}>
													<Button size="small" color="primary">
														{service["title"]}
													</Button>
												</Link>
											</Box>
											<Box>
												<Chip

													size="small"
													icon={service["protected"]? <LockOutlined/>:<LockOpenOutlined/>}
													color="primary"
													label={service["protected"]?"protected":"open"}
												/>
											</Box>
										</CardActions>
									</Card>
								</Grid>
							))
						:
							(
								<Grid item xs={12} sm={12} md={6} lg={4}>
									<LinearProgress />
									<Typography variant="body2" color="textSecondary" align="center">
										Loading...
									</Typography>
								</Grid>
							)
						}
					</Grid>
				</Container>
				<Container maxWidth="sm" className={classes.footer}>
					<Box m={6}>
						<Typography variant="body2" color="textSecondary" align="center">
							<Divider />
							{'Copyright © '}
							{'Oli '}
							{new Date().getFullYear()}
							{'.'}
						</Typography>
					</Box>
				</Container>
			</Box>
		);
	}
}

export default  withRouter(withStyles(styles)(IndexPage))