import { createMuiTheme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#424242',
			main: '#212121',
			dark: '#000',
			contrastText: '#fff',
		},
		secondary: blue,
		background: {
			default: '#eeeeee',
		},
	},
});

export default theme;