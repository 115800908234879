import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Grid from "@material-ui/core/Grid";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import AudioPlayer from 'material-ui-audio-player';
import PlayCircleFilledIcon from '@material-ui/icons/PlayArrow';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import TimeAgo from 'timeago-react';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { withSnackbar } from 'notistack';

import 'podcast-feed-parser';
import {api, handleError} from "../helpers/api";
const podcastFeedParser = require("podcast-feed-parser");

const base = "https://api.oli.fyi/podcast/";

const styles = theme => ({
	spinner: {
		width: 'min-content',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	centering: {
		textAlign: "center"
	},
	vcenter: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: theme.spacing(2)

	},
	heading: {
		flexBasis: '50%',
		flexShrink: 0,
		fontWeight: 'bold'
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	playerContainer: {
		maxWidth: '400px !important'
	},
	stickyCard: {
		position: 'sticky',
		top: theme.spacing(4),
	},
	media: {
		width: '50%',
		height: 0,
		paddingTop: '50%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	playerControls: {
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingLeft: theme.spacing(2)
	},
	activeExp: {
		color: theme.palette.secondary.main
	},
	imgContainer: {
		float: 'left',
		position: 'relative',
		'&:hover $containerImg':{
			filter: 'brightness(50%);'
		},
		'&:hover $playIcon': {
			opacity: '1'
		}
	},
	containerImg: {
		filter: 'none',
		transition: 'filter 1s'
	},
	playIcon: {
		position : 'absolute',
		opacity: '0',
		color: 'white',
		top: '50%',
		margin: '0 auto',
		left: 0,
		right: 0,
		transform: 'translateY(-50%)',
		transition: 'opacity 1s'
	},
	loginFields: {
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1),
	},
	loginField: {
		width: '100%',
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1),
	}
});


class Podcast extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: base + this.props.match.params.podcastSlug,
			podcast: null,
			episode: null,
			needsPassword: false,
			username: null,
			password: null,
		};
	}

	async fetch() {
		try {
			let response;
			if (this.state.username && this.state.password) {
				response = await api.get(this.state.url,{
					auth: {
						username: this.state.username,
						password: this.state.password
					}
				});
			}
			else {
				response = await api.get(this.state.url);
			}

			const xmlFeed = response.data;
			const podcast = await podcastFeedParser.getPodcastFromFeed(xmlFeed);

			this.setState({podcast: podcast});
			this.setState({episode: podcast.episodes[0]})
		} catch (error) {
			if (error.response.status === 401) {
				this.setState({needsPassword: true});

				// If password and username already set user provided wrong password
				if (this.state.username && this.state.password) {
					this.props.enqueueSnackbar('Username or password wrong', {
						variant: 'error',
					});
					this.setState({password: null})
				}
			}
			else {
				alert(`Something went wrong while fetching data: \n${handleError(error)}`);
			}

		}
	}

	async componentDidMount() {
		this.fetch();
	}

	handleInputChange(key, value) {
		// Example: if the key is username, this statement is the equivalent to the following one:
		// this.setState({'username': value});
		this.setState({ [key]: value });
	}


	render() {
		const { classes } = this.props;

		if (!this.state.podcast && !this.state.needsPassword) {
			return (
				<Box>
					<Container maxWidth="sm">
						<Card className={classes.vcenter}>
							<Box className={classes.centering}>
								<CircularProgress className={classes.spinner} color="secondary" />
							</Box>
							<Typography variant="h2" component="h1" gutterBottom align="center">
								Loading podcast
							</Typography>
						</Card>
					</Container>
				</Box>
			);
		}
		else if (this.state.needsPassword) {
			return (
				<Box>
					<Container maxWidth="sm">
						<Card className={classes.vcenter}>
							<Typography variant="h2" component="h1" gutterBottom align="center">
								Login
							</Typography>
							<Typography variant="subtitle2" component="span" gutterBottom align="center">
								This feed is password protected, so we need you to log in.
							</Typography>
							<form>
								<Box className={classes.loginFields}>
									<TextField
										label="Username"
										variant="outlined"
										className={classes.loginField}
										onChange={e => {
											this.handleInputChange('username', e.target.value);
										}}
										value={this.state.username}
									/>
									<TextField
										label="Password"
										type="password"
										variant="outlined"
										className={classes.loginField}
										onChange={e => {
											this.handleInputChange('password', e.target.value);
										}}
									/>
								</Box>
								<Box className={classes.centering}>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										onClick={() => {
											this.setState({needsPassword: false});
											this.fetch();
										}}
									>
										Let's do this
									</Button>
								</Box>
							</form>
						</Card>
					</Container>
				</Box>
			);
		}
		else{
			return (
				<Box>
					<Container maxWidth="lg">
						<Box m={8}>
							<Typography variant="h2" component="h1" gutterBottom align="center">
								{this.state.podcast.meta.title}
							</Typography>
						</Box>
					</Container>
					<Container>
						<Grid container spacing={4}>
							<Grid item xs className={classes.playerContainer}>
								{this.state.episode?
									<Card className={classes.stickyCard}>
										<CardMedia
											className={classes.media}
											image={this.state.episode.imageURL?this.state.episode.imageURL:this.state.podcast.meta.imageURL}
											title={this.state.episode.title}
										/>
										<Box className={classes.playerControls}>
											<AudioPlayer
												key={this.state.episode.link}
												src={this.state.episode.enclosure.url}
												download={true}
												elevation="0"
												width='100%'
											/>
										</Box>
										<CardHeader
											title={this.state.episode.title}
											subheader={moment(this.state.episode.pubDate).format("MMMM Do YYYY")}
										/>
										<CardContent>
											<Typography variant="body2" color="textSecondary" component="p">
												{this.state.episode.description}
											</Typography>
										</CardContent>
									</Card>
									:
									<Card></Card>
								}
							</Grid>
							<Grid item xs>
								<Box>
									{this.state.podcast.episodes.map((episode) => (
										<ExpansionPanel
											key={ episode.link }
											TransitionProps={{ unmountOnExit: true }} >
											<ExpansionPanelSummary
												expandIcon={<ExpandMoreIcon />}
												className={this.state.episode === episode ? classes.activeExp :''}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<Typography className={classes.heading}>
													{ episode.title }
												</Typography>
												<Typography className={classes.secondaryHeading}>
													<Tooltip title={moment(episode.pubDate).format("dddd, MMMM Do YYYY")} placement="top">
														<TimeAgo datetime={episode.pubDate} />
													</Tooltip>
												</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<Typography>
													<Grid container spacing={2}>
														<Grid
															item
															xs={3}
															className={classes.imgContainer}
															onClick={() =>
																this.setState({episode: episode})
															}
														>
															<LazyLoadImage
																className={classes.containerImg}
																alt={episode.title}
																effect="blur"
																src={episode.imageURL?episode.imageURL:this.state.podcast.meta.imageURL}
																width="100%"
																placehodlerSrc={this.state.podcast.meta.imageURL}
															/>
															<PlayCircleFilledIcon
																fontSize='large'
																className={classes.playIcon}
															/>
														</Grid>
														<Grid item xs={9}>
															{ episode.description }
														</Grid>
													</Grid>
												</Typography>
											</ExpansionPanelDetails>
										</ExpansionPanel>
									))}
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			);
		}

	}

}

export default withRouter(withStyles(styles)(withSnackbar(Podcast)));