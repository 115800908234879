import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import IndexPage from "./pages/index";
import Podcast from "./pages/podcast";


function App() {
  return (
      <Router>
        <Switch>
          <Route
              exact
              path="/"
              component={IndexPage}
          />
          <Route
              path="/podcast/:podcastSlug"
              component={Podcast}
          />
        </Switch>
      </Router>

  );
}

export default App;
